@media only screen and (max-width: 1450.98px) {

    .track_order_outer{

        padding: 80px 0px 0px;
    }

  .promisses_box p {
    font-size: 12px;
  }
  .promisses_box h3 {
    font-size: 13px;
    font-weight: 600;
  }
  .main_icons h4 {
    font-size: 28px;
  }
  .social_community li img {
    width: 32px;
    height: 32px;
  }
  .my_header header {
    width: 100% !important;
  }
  .global_btn {
    padding: 12px 20px !important;
  }
  .add_frame button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991.98px) {
  .collection_inner {
    padding: 30px 0 0;
  }
  .join_drp .dropdown-menu {
    display: block;
    width: 210px;
    top: 45px;
    right: 0;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    padding: 10px;
  }
  .join_drp .dropdown-menu a {
    padding: 13px 0 !important;
  }
  .browse_with_img {
    display: none;
  }
  .filter_mobile_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cs_prev_nextbtn {
    display: none;
  }
  .search-bar button {
    position: absolute;
    background-color: unset;
    border: unset;
    color: #2b2b2b;
    padding: unset;
  }
  .product-details {
    padding: 15px 0 0;
  }
  .product-details .neweletter_main {
    padding: 0 0 0;
  }
  .product_add_details .artworks {
    padding: 40px 15px;
    /* background: #f7f7f7; */
  }
  .product_add_details {
    padding: 40px 0;
  }
  .thumbnails img {
    width: 65px !important;
    height: 65px !important;
    border: 3px solid #fff;
    border-radius: 5px;
  }
  .thumbnails {
    flex-direction: row;
    margin-top: -100px;
    padding: 0 20px;
  }
  .main_product_slide {
    flex-direction: column-reverse;
  }
  .cart_page .neweletter_main {
    padding: 40px 0;
  }
  .cart_page {
    padding: 40px 0 0;
  }
  .wislist_page .product_box {
    overflow: hidden;
    margin: 0 3px 0;
  }
  .wishlist_box {
    padding: 40px 0 0;
  }
  .product_list_page .neweletter_main {
    padding: 40px 0 0;
  }
  .productlist .product_box {
    overflow: hidden;
    margin: 0 3px 0;
  }
  .breadcrumbs {
    margin-bottom: 40px;
  }
  .Filter_mobile_btn {
    display: block;
  }
  .offcanvas-body .filter_product {
    display: block;
  }
  .filter_product {
    display: none;
  }
  .main_icons h4 {
    margin-bottom: 20px !important;
  }
  .join_community .main_ouetr {
    padding: 15px;
  }
  .slider_big img {
    height: 560px !important;
  }
  .product_box {
    overflow: hidden;
    margin: 0 0 0;
  }
  .sortlist_product .wishlist_box {
    padding: 25px 0;
  }
  .product_box:hover {
    transform: unset;
    transition: unset;
  }
  .wishlist_box .col {
    padding: 0;
  }
  .product_box {
    overflow: hidden;
  }
  .wislist_page .global_btn {
    background-color: teal !important;
    border-radius: 10px !important;
    padding: 8px 22px !important;
  }
  .order_details_single h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {


  .sibebar_page{ display: none;}

  .just_bought h1 {
    font-size: 22px;
  }
  .role_choose span {
    font-size: 16px;
  }
  .role_icon {
    width: 65px;
    height: 65px;
    font-size: 18px;
  }
  .role-box h2 {
    font-size: 18px;
  }
  .modal-all .modal-title {
    font-size: 18px;
  }
  .artist_page {
    padding: 30px 0;
  }
  .aplha_list button {
    border: none;
    border: 1px solid #036565;
    width: 22px;
    height: 22px;
    font-size: 12px;
    display: flex;
    border-radius: 5px;
    font-weight: 600;
    color: #036565;
    align-items: center;
    justify-content: center;
  }
  .artist-login-container {
    min-height: calc(100vh - 104px);
  }
  .login-title {
    font-size: 24px;
  }
  .witdraw_amount .nav-tabs {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .witdraw_amount .nav-tabs button {
    text-wrap: nowrap;
  }
  .witdraw_amount .nav-tabs .nav-link {
    padding: 10px 12px !important;
    font-size: 14px;
  }
  .top_ten_arts img {
    width: 120px;
    height: 120px;
  }
  .cnt_slider h1,
  .cnt_slider h2 {
    color: #fff;
    font-size: 22px;
  }
  .grid_box_artwork img {
    height: unset;
  }
  .slider_sec {
    padding: 20px 0 0;
  }
  .right_btn {
    position: unset;
    text-align: center;
  }
  .level_heading {
    margin-bottom: 20px;
  }
  .top_ten .left_global_heading {
    margin-top: 20px;
  }
  .cart_em_img img {
    width: 90px !important;
  }
  .order_faild_page {
    padding: 40px 0 40px;
  }
  .inner-menus li a.active {
    color: #fff !important;
    font-weight: 600;
    opacity: 1;
    font-size: 14px;
    background: purple;
  }
  .profile-add .order_all_details_uter {
    padding: 10px;
  }
  .order_all_details {
    flex-direction: column;
  }
  .account-content-box {
    background: #fff;
    border: 1px solid #aaa;
    padding: 15px 10px;
    overflow: hidden;
  }
  .profile-add {
    padding: 40px 0 0;
  }
  .inner-menus ul {
    margin: 0;
  }
  .inner-menus li {
    margin: 0;
  }
  .inner-menus li a {
    text-wrap: nowrap;
    font-weight: 500;
    background: #efefef;
    font-size: 16px;
    display: inline-block;
    color: #000;
    padding: 9px;
    opacity: 0.5;
    border-radius: 10px;
  }
  .inner-menus ul {
    display: flex;
    gap: 10px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
  .inner-menus ul::-webkit-scrollbar {
    display: none;
  }
  .order_details_single h2 {
    font-size: 14px;
  }
  .order_details_single h2 {
    flex-direction: column;
    align-items: start;
    gap: 30px;
  }
  .join_drp .dropdown {
    position: relative;
    background: #efefef;
    width: 35px;
    height: 35px;
  }
  .product_name {
    font-size: 15px;
  }
  .wishlist {
    width: 30px;
    height: 30px;
    right: 25px;
    top: 15px;
    font-size: 14px;
  }
  .about_details_product .q_select button {
    padding: 2px;
  }
  .product_box img {
    height: 100%;
  }
  .add-remove-btn {
    margin-top: 15px;
    justify-content: end;
    margin-bottom: 20px;
  }
  .price_quantity h3 {
    margin-bottom: 10px !important;
  }
  .about_frame ul {
    margin: 0 0 10px;
  }
  .price_quantity {
    text-align: start !important;
  }
  .about_frame {
    flex-direction: column;
    align-items: start !important;
    justify-content: start !important;
  }
  .custom-form h1 {
    font-size: 26px;
  }
  .all_categores {
    padding: 40px 0 !important;
  }
  .main_homapage .popular_collection {
    margin-bottom: 30px;
  }
  .left_global_heading h3 {
    font-size: 22px;
  }
  .top_ten {
    padding: 0 0 40px;
  }
  .newartwork {
    padding: 40px 0 0;
  }
  .social_community li img {
    width: 27px;
  }
  .main_icons h4 {
    font-size: 24px;
    font-weight: 600;
  }
  .product_list {
    padding: 25px 0 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .lottery_outer .main {
    height: 360px;
    margin: auto;
    width: 360px;
  }
  .pointer {
    left: 53.55%;
  }
  .lottery_outer .span8 {
    left: 190px;
  }
  .lottery_outer .span7 {
    right: 172px;
  }
  .lottery_outer .span6 {
    left: 170px;
  }
  .lottery_outer .span5 {
    right: 175px;
  }
  .lottery_outer .span5 {
    right: 175px;
  }
  .cat_gories_mobile ul li a {
    font-size: 14px;
  }
  .top_ten .collection_grid {
    margin: 15px;
  }
  .all_categores .left_global_heading {
    flex-direction: column;
  }
  .faq-page .according-inner {
    padding: 15px;
  }
  .contact_us_page_static {
    padding: 30px 0 30px;
  }
  contact_us_page_static .blog_cnt h1 {
    font-size: 24px;
    font-weight: 600;
  }
  .blog_box {
    padding: 10px;
    margin: 30px 0 0;
  }
  .tran_sec ul {
    padding: 0;
    -moz-column-count: 1;
    -moz-column-gap: 20px;
    -webkit-column-count: 1;
    -webkit-column-gap: 20px;
    column-count: 1;
    column-gap: 20px;
  }
  .tab_design button {
    padding: 12px 30px;
  }
  .btn_flex input {
    width: 100% !important;
  }
  .btn_flex {
    flex-direction: column;
    align-items: start !important;
  }
  .shode_box .col {
    padding: 0 !important;
  }
  .acces_btn_artist {
    flex-direction: column;
    gap: 20px;
  }
  .acces_btn_artist button {
    width: 100%;
  }
  .main_wallet_box {
    flex-direction: column;
  }
  .neweletter {
    background-position: left;
  }
  .cat_box_inner img {
    height: 164px;
  }
  .level_heading {
    font-size: 18px;
  }
  .main_purchase {
    flex-direction: column;
    justify-content: start;
    align-items: start !important;
  }

  .add_frame button {
    text-wrap: nowrap;
  }


  .add_frame{display: unset; }


}
